:root {
  --matrix-green: #00ff41;
  --matrix-dark: #000000;
  --matrix-glow: 0 0 10px rgba(0, 255, 0, 0.5);
  --header-height: 60px;
  --font-family-mono: 'Courier New', monospace;
}

/* Base styles */
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-mono);
  background: var(--matrix-dark);
  color: var(--matrix-green);
  min-height: 100vh;
  overflow-x: hidden;
}

/* App Layout */
.App {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  gap: 0;
}

/* Matrix Title Animation */
.matrix-title {
  text-align: center;
  padding: 30px 0;
  margin-bottom: 40px;
  z-index: 30;
  position: relative;
}

.scramble-title {
  font-family: var(--font-family-mono);
  font-size: 3.5rem;
  font-weight: 500;
  letter-spacing: 0.15em;
  color: var(--matrix-green);
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5),
               0 0 20px rgba(0, 255, 0, 0.3),
               0 0 30px rgba(0, 255, 0, 0.2);
  margin: 0;
  text-transform: uppercase;
  line-height: 1;
}

/* Remove conflicting animations */
.matrix-title h1 {
  animation: none;
  opacity: 1;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .scramble-title {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .scramble-title {
    font-size: 2rem;
  }
  
  .matrix-title {
    padding: 20px 0;
    margin-bottom: 15px;
  }
}

/* Add a fallback sci-fi font */
@font-face {
  font-family: 'Blade Runner';
  src: url('https://fonts.cdnfonts.com/css/blade-runner-movie-font') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Update Code Converter styles for better matrix rain visibility */
.code-converter {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(0, 255, 65, 0.15);
  border-radius: 16px;
  box-shadow: 
    0 4px 24px rgba(0, 255, 65, 0.05),
    inset 0 0 30px rgba(0, 255, 65, 0.03);
  transition: all 0.3s ease;
  margin: 20px;
  padding: 20px;
  position: relative;
  z-index: 2;
}

/* Form controls */
textarea, select {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid rgba(0, 255, 65, 0.2);
  color: var(--matrix-green);
  font-family: var(--font-family-mono);
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  transition: all 0.2s ease;
}

textarea:focus, select:focus {
  background: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 255, 65, 0.3);
  box-shadow: 0 0 15px rgba(0, 255, 65, 0.1);
  outline: none;
}

/* Button styles */
button {
  background: rgba(0, 0, 0, 0.4);
  color: var(--matrix-green);
  border: 1px solid rgba(0, 255, 65, 0.3);
  padding: 8px 16px;
  border-radius: 4px;
  font-family: var(--font-family-mono);
  cursor: pointer;
  transition: all 0.2s ease;
}

button:hover {
  background: rgba(0, 255, 65, 0.1);
  border-color: rgba(0, 255, 65, 0.4);
  box-shadow: 0 0 15px rgba(0, 255, 65, 0.15);
}

button:active {
  transform: translateY(1px);
}

/* Prompt History styles */
.prompt-history {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(0, 255, 65, 0.15);
  border-radius: 8px;
}

.terminal-header {
  background: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 255, 65, 0.2);
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .matrix-title h1 {
    font-size: 1.8rem;
  }

  .code-converter {
    padding: 10px;
    margin: 5px;
  }

  button, select, textarea {
    font-size: 16px;
    min-height: 44px;
  }

  .main-content {
    padding: 10px;
    gap: 10px;
  }
}

/* Touch Device Optimizations */
@media (hover: none) and (pointer: coarse) {
  button, 
  select, 
  [role="button"] {
    min-height: 44px;
    min-width: 44px;
  }
}

/* Prompt History Styles */
.prompt-history {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(0, 255, 65, 0.1);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 255, 65, 0.1);
  margin: 10px;
  transition: all 0.3s ease;
  overflow: hidden;
}

.prompt-history.collapsed {
  max-height: 60px;
}

.prompt-history.expanded {
  max-height: 400px;
}

.terminal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 255, 65, 0.2);
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.expand-button {
  background: transparent;
  border: 1px solid var(--matrix-green);
  color: var(--matrix-green);
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
}

.terminal-window {
  overflow-y: auto;
  max-height: 340px;
  padding: 10px;
}

.terminal-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid rgba(0, 255, 65, 0.1);
}

.prompt-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.timestamp {
  font-size: 0.8em;
  color: rgba(0, 255, 65, 0.7);
}

.prompt-text {
  word-break: break-word;
}

.paste-button {
  background: transparent;
  border: none;
  color: var(--matrix-green);
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.paste-button:hover {
  opacity: 1;
}

/* Scrollbar styles for the terminal window */
.terminal-window::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.3);
}

.terminal-window::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
}

.terminal-window::-webkit-scrollbar-thumb {
  background: rgba(0, 255, 65, 0.3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 4px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .prompt-history {
    margin: 5px;
  }

  .terminal-window {
    max-height: 280px;
  }

  .terminal-line {
    padding: 6px;
  }
}

/* Scramble Text Animation */
.scramble-text {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--matrix-green);
  font-family: var(--font-family-mono);
  pointer-events: none;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
  background: rgba(0, 0, 0, 0.9);
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
  line-height: inherit;
}

/* Style container for select elements to handle overlay positioning */
.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Ensure select elements work with the overlay */
select {
  position: relative;
  z-index: 1;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  padding-right: 24px; /* Space for the dropdown arrow */
}

/* Add a custom dropdown arrow */
.select-container::after {
  content: '▼';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--matrix-green);
  pointer-events: none;
  font-size: 12px;
}

/* Add container for the main content */
.main-content {
  position: relative;
  z-index: 10;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .code-converter, .prompt-history {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
}

/* Add side-by-side layout */
.code-output-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.code-section, .conversion-section {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(0, 255, 65, 0.15);
  border-radius: 8px;
  padding: 15px;
  position: relative;
}

/* Loading overlay with matrix spiral */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 100;
}

/* Convert button styles */
.convert-button {
  background: rgba(0, 0, 0, 0.4);
  color: var(--matrix-green);
  border: 1px solid rgba(0, 255, 65, 0.3);
  padding: 10px 20px;
  border-radius: 4px;
  font-family: var(--font-family-mono);
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 10px;
}

.convert-button:hover {
  background: rgba(0, 255, 65, 0.1);
  border-color: rgba(0, 255, 65, 0.4);
  box-shadow: 0 0 15px rgba(0, 255, 65, 0.15);
}

.convert-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .code-output-container {
    grid-template-columns: 1fr;
  }
}

.login-prompt-button {
  background: transparent;
  color: var(--matrix-green);
  border: 1px solid var(--matrix-green);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Orbitron', sans-serif;
  transition: all 0.3s ease;
}

.login-prompt-button:hover {
  background: var(--matrix-green);
  color: var(--matrix-dark);
  box-shadow: 0 0 10px var(--matrix-green);
}

/* Update existing styles */
.main-container {
  position: relative;
  min-height: 100vh;
  z-index: 1;
  background: transparent;
}

.matrix-title {
  text-align: center;
  padding: 15px 0;
  z-index: 2;
  position: relative;
  color: #00ff00;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.matrix-title h1 {
  margin: 0;
  font-family: 'Courier New', monospace;
  font-size: 2.5rem;
  letter-spacing: 2px;
}

.code-converter {
  position: relative;
  z-index: 3;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

/* Add these styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.modal-content {
  background: var(--matrix-dark);
  border: 1px solid var(--matrix-green);
  border-radius: 8px;
  padding: 20px;
  position: relative;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 255, 65, 0.2);
  animation: modalFadeIn 0.3s ease-out;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: var(--matrix-green);
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  transition: all 0.3s ease;
}

.modal-close:hover {
  color: #fff;
  transform: scale(1.1);
}

/* Animations */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--matrix-dark);
}

::-webkit-scrollbar-thumb {
  background: var(--matrix-green);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00ff00;
}

/* Responsive design */
@media (max-width: 768px) {
  .matrix-title h1 {
    font-size: 2.5rem;
  }
  
  .modal-content {
    padding: 15px;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .matrix-title h1 {
    font-size: 2rem;
  }
  
  .modal-content {
    padding: 10px;
  }
  
  .modal-close {
    font-size: 20px;
  }
}