:root {
  --matrix-green: #00ff41;
  --matrix-dark: #0a0a0a;
  --matrix-glow: 0 0 10px #00ff41, 0 0 20px #00ff41, 0 0 30px #00ff41;
}

/* Main container */
.code-converter {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Form section */
.form-section {
  margin-bottom: 40px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  color: var(--matrix-green);
  font-family: 'Courier New', monospace;
}

/* Code output container */
.code-output-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
  height: min(calc(100vh - 400px), 600px);
  min-height: 400px;
}

/* Code section */
.code-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
}

.code-textarea {
  flex: 1;
  width: 100%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid var(--matrix-green);
  border-radius: 8px;
  color: var(--matrix-green);
  font-family: 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  min-height: 450px;
}

.code-textarea:focus {
  outline: none;
  box-shadow: var(--matrix-glow);
}

/* Preview section */
.conversion-section {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.preview-content {
  flex: 1;
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid var(--matrix-green);
  border-radius: 8px;
  overflow: auto;
}

/* Terminal Animation */
.terminal-animation {
  background: var(--matrix-dark);
  border: 1px solid var(--matrix-green);
  border-radius: 4px;
  padding: 15px;
  min-height: 200px;
  height: auto;
  max-height: 80vh;
  font-family: 'Courier New', monospace;
  color: var(--matrix-green);
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.terminal-line {
  margin-bottom: 8px;
  animation: typing 2s steps(40) infinite;
  font-size: 14px;
  white-space: pre-wrap;
  word-break: break-word;
}

.terminal-cursor {
  position: absolute;
  width: 10px;
  height: 20px;
  background: var(--matrix-green);
  animation: blink 1s step-end infinite;
}

/* Buttons */
.convert-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid var(--matrix-green);
  color: var(--matrix-green);
  font-family: 'Courier New', monospace;
  cursor: pointer;
  transition: all 0.3s ease;
}

.convert-button:hover {
  background: rgba(0, 255, 65, 0.1);
  box-shadow: var(--matrix-glow);
}

/* Prompt History */
.prompt-history-section {
  margin-top: 20px;
  padding: 15px;
  background: var(--matrix-dark);
  border: 1px solid var(--matrix-green);
  border-radius: 4px;
}

.prompt-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.prompt-item {
  padding: 10px;
  border: 1px solid var(--matrix-green);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.prompt-item:hover {
  background: rgba(0, 255, 65, 0.1);
  box-shadow: var(--matrix-glow);
}

/* Animations */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  50% { opacity: 0 }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .code-output-container {
    grid-template-columns: 1fr;
    height: auto;
  }

  .code-section,
  .conversion-section {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .code-converter {
    padding: 10px;
  }

  .terminal-animation {
    padding: 10px;
    min-height: 150px;
  }

  .terminal-line {
    font-size: 12px;
    margin-bottom: 6px;
  }

  .code-section,
  .conversion-section {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .terminal-animation {
    min-height: 120px;
  }

  .terminal-line {
    font-size: 11px;
    margin-bottom: 4px;
  }
}
  