@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

:root {
  --matrix-green: #00ff41;
  --matrix-dark: #000000;
  --matrix-glow: 0 0 10px rgba(0, 255, 0, 0.5);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family-mono);
  background: var(--matrix-dark);
  color: var(--matrix-green);
  min-height: 100vh;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: var(--header-height);
  background-color: rgba(0, 0, 0, 0.9);
  border-bottom: 1px solid var(--matrix-green);
  position: sticky;
  top: 0;
  z-index: 100;
}

.main-content {
  flex: 1;
  padding: 20px;
  position: relative;
  z-index: 5;
}

.matrix-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  pointer-events: none;
}

/* Ensure content appears above matrix background */
.app-header,
.main-content {
  position: relative;
  z-index: 1;
}

/* Mobile optimization */
@media (max-width: 768px) {
  .app-header {
    padding: 0 15px;
    height: 50px;
  }

  .main-content {
    padding: 15px;
    gap: 15px;
  }
}

/* Small phone optimization */
@media (max-width: 480px) {
  .app-header {
    padding: 0 10px;
  }

  .main-content {
    padding: 10px;
    gap: 10px;
  }
}

/* Touch device optimization */
@media (hover: none) and (pointer: coarse) {
  button {
    min-height: 44px;
  }
}

/* Fix mobile layout */
@media (max-width: 768px) {
  .App {
    grid-template-rows: auto auto 1fr;
  }

  .main-content {
    padding: 10px;
    gap: 10px;
  }
}

.matrix-title {
  margin-top: 40px;
  margin-bottom: 20px;
  z-index: 10;
  position: relative;
}

.matrix-title h1 {
  font-family: 'Courier New', monospace;
  font-size: 3.5rem;
  font-weight: 500;
  letter-spacing: 0.15em;
  color: var(--matrix-green);
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5),
               0 0 20px rgba(0, 255, 0, 0.3),
               0 0 30px rgba(0, 255, 0, 0.2);
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

/* Add loading animation */
.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: var(--matrix-dark);
}

.matrix-loading {
  color: var(--matrix-green);
  font-family: 'Courier New', monospace;
  font-size: 24px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

@media (max-width: 768px) {
  .matrix-title h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .matrix-title h1 {
    font-size: 2rem;
  }
}
