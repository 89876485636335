.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.95);
}

.terminal-container {
  width: 100%;
  max-width: 500px;
  min-height: 300px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 255, 65, 0.2);
  border-radius: 16px;
  padding: 25px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 255, 0, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.terminal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 255, 65, 0.2);
}

.terminal-icon {
  color: var(--matrix-green);
  margin-right: 10px;
  font-size: 1.2em;
}

.terminal-title {
  color: var(--matrix-green);
  font-family: 'Orbitron', monospace;
  font-size: 1.2em;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 255, 65, 0.5);
}

.terminal-text {
  font-family: 'Courier New', monospace;
  color: var(--matrix-green);
  white-space: pre-wrap;
  margin: 0;
  height: calc(100% - 120px);
  overflow: hidden;
  text-shadow: 0 0 5px rgba(0, 255, 65, 0.5);
  line-height: 1.6;
}

.login-button {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  background: rgba(0, 255, 65, 0.1);
  border: 1px solid rgba(0, 255, 65, 0.3);
  border-radius: 8px;
  color: var(--matrix-green);
  font-family: 'Orbitron', monospace;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.login-button:hover {
  background: rgba(0, 255, 65, 0.2);
  border-color: rgba(0, 255, 65, 0.5);
  box-shadow: 0 0 20px rgba(0, 255, 65, 0.2);
  transform: translateY(-1px);
}

.login-button:active {
  transform: translateY(1px);
}

.login-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add glowing effect behind the container */
.terminal-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(0, 255, 65, 0.1) 0%,
    transparent 70%
  );
  transform: translate(-50%, -50%);
  z-index: -1;
  pointer-events: none;
}

/* Add animated border glow */
@keyframes borderGlow {
  0% {
    border-color: rgba(0, 255, 65, 0.2);
    box-shadow: 0 0 30px rgba(0, 255, 0, 0.15);
  }
  50% {
    border-color: rgba(0, 255, 65, 0.4);
    box-shadow: 0 0 40px rgba(0, 255, 0, 0.25);
  }
  100% {
    border-color: rgba(0, 255, 65, 0.2);
    box-shadow: 0 0 30px rgba(0, 255, 0, 0.15);
  }
}

.terminal-container {
  animation: borderGlow 4s ease-in-out infinite;
}

/* Loading spinner */
.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 255, 65, 0.1);
  border-top-color: var(--matrix-green);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive design */
@media (max-width: 600px) {
  .terminal-container {
    padding: 20px;
    min-height: 250px;
  }

  .terminal-title {
    font-size: 1em;
  }

  .terminal-text {
    font-size: 0.9em;
  }
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  border-radius: 8px;
  color: #ff4141;
  font-family: 'Orbitron', monospace;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.logout-button:hover {
  background: rgba(255, 0, 0, 0.2);
  border-color: rgba(255, 0, 0, 0.5);
  box-shadow: 0 0 20px rgba(255, 0, 0, 0.2);
  transform: translateY(-1px);
}

.logout-button:active {
  transform: translateY(1px);
}

.logout-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .terminal-header {
    flex-wrap: wrap;
    gap: 10px;
  }

  .logout-button {
    font-size: 0.8em;
    padding: 6px 10px;
  }
} 